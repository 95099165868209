<template>
    <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 row p-1 m-0 border border-primary">
            <h5 class="h5 text-center col-12 border-bottom border-primary">{{ t('general.number') }}</h5>
            <div class="col-12 col-sm-4">
                <span>{{ t('general.select_color') }}</span>
                <input type="color" v-model="obj.ncolor" class="form-control">
            </div>
            <div class="col-12 col-sm-4">
                <span>{{ t('general.number') }}</span>
                <input type="text" maxlength="3" minlength="0" v-model="obj.number" class="form-control">
            </div>
            <div class="col-12 col-sm-4">
                <span>{{ t('general.font_size') }}</span>
                <input type="number" v-model="obj.nfsize" class="form-control" min="1">
            </div>
        </div>
        <div class="col-12 col-sm-6 row p-1 m-0 border border-primary">
            <h5 class="h5 text-center col-12 border-bottom border-primary">{{ t('general.name') }}</h5>
            <div class="col-12 col-sm-4">
                <span>{{ t('general.select_color') }}</span>
                <input type="color" v-model="obj.color" class="form-control">
            </div>
            <div class="col-12 col-sm-4">
                <span>{{ t('general.name') }}</span>
                <input type="text" v-model="obj.text" class="form-control">
            </div>
            <div class="col-12 col-sm-4">
                <span>{{ t('general.font_size') }}</span>
                <input type="number" v-model="obj.fsize" class="form-control" min="1">
            </div>
        </div>
        <span class="col-12 text-center p-2">{{ t('general.click-to-add') }}</span>
        <button v-for="path in shirts" :key="path" style="width: 55px; height: 55px;">
            <img :src="getFullPath(path)" :alt="path" class="img" @click="add">
        </button>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { fabric } from 'fabric';
    import { scale } from '@/utilities/DrawFunctions';
    import Api from '@/utilities/Api';
    
    export default {
        name: 'ShirtComponent',
        props: {
            shirts: Array
        },
        data() {
            return {
                obj: {
                    color: '#ffffff',
                    fsize: 9,
                    nfsize: 12,
                    ncolor: '#ffffff',
                    text: 'jogador',
                    number: 10
                },
                Api
            }
        },
        async created() {
            // get icons
        },
        methods: {
            t,
            getFullPath(path) {
                return `${Api.url}elements?path=${path}`
            },
            add(event) {
                const image = event.target
                // imagem
                const tempImage = new Image();
                tempImage.crossOrigin = "anonymous"; // Permite carregamento seguro
                tempImage.src = image.src;

                tempImage.onload = () => {
                    // Converter a imagem para Base64
                    const tempCanvas = document.createElement('canvas');
                    const tempCtx = tempCanvas.getContext('2d');
                    tempCanvas.width = tempImage.width;
                    tempCanvas.height = tempImage.height;
                    tempCtx.drawImage(tempImage, 0, 0);

                    const base64Image = tempCanvas.toDataURL("image/png");

                    // Usar fabric.Image.fromURL para criar o objeto de imagem do Fabric
                    fabric.Image.fromURL(base64Image, (fabricImg) => {
                        // Configurar a imagem
                        fabricImg.set({
                            top: window.canvas.height / 2,
                            left: window.canvas.width / 2,
                        });

                        fabricImg.scaleToWidth(image.width);
                        fabricImg.scaleToHeight(image.height);

                        // Texto número do jogador
                        const n = new fabric.Textbox(`${this.obj.number}`, {
                            fontSize: this.obj.nfsize,
                            fill: this.obj.ncolor,
                            textAlign: "center",
                            fontWeight: 'bold',
                            fontFamily: 'Calibre',
                        });
                        n.set({
                            top: window.canvas.height / 2 + image.height / 2 - n.height / 2,
                            left: window.canvas.width / 2 + image.width / 2 - n.width / 2,
                        });

                        // Texto nome do jogador
                        const t = new fabric.Textbox(`${this.obj.text}`, {
                            fontSize: this.obj.fsize,
                            fill: this.obj.color,
                            textAlign: "center",
                            fontWeight: 'bold',
                            fontFamily: 'Calibre',
                        });
                        t.set({
                            top: window.canvas.height / 2 + image.height,
                            left: window.canvas.width / 2 + image.width / 2 - t.width / 2,
                        });

                        // criar o grupo
                        const group = new fabric.Group([fabricImg, n, t], {
                            lockScalingFlip : true,
                            typeOfGroup : 'camiseta',
                            userLevel : 2,
                            scaleX: scale,
                            scaleY: scale,
                            name:'my_t-shirt',
                            uuid : new Date().valueOf(),
                            type : 'group'
                        })
                        window.canvas.add(group)
                        window.canvas.renderAll()
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    button {
        padding: 2px;
        background-color: transparent;
        border: none;
        margin: 0px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>
