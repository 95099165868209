import { fabric } from "fabric";
import deleteImg from '@/assets/icons/close.png'
import copyImg from '@/assets/icons/clipboard.png'
import Api from './Api.js'
// import { addShortCuts } from '@/utilities/KeyBoardShortCuts'
import { mouseDown, mouseMove, mouseUp } from '@/utilities/Events.js'

export const scale = 2.031
const renderIcon = (icon) => {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size/2, -size/2, size, size);
        ctx.restore();
    }
}

export const deleteObject = () => {
    const els = window.canvas.getActiveObjects()
    els.forEach(x => {
        if (x.trajectory) {
            if (x.trajectory.control) {
                window.canvas.remove(x.trajectory.control)
            }
            window.canvas.remove(x.trajectory)
        }
        if (x.__parent && !x.curve) {
            return
        } else if (!x.curve) {
            if (x._objects) {
                window.canvas.getObjects().forEach(o => {
                    if (x.uuid == o.uuid) {
                        window.canvas.remove(o)
                    }
                })
            }

            window.canvas.remove(x)
        } else {
            const curve = x.curve
            curve.points && curve.points.forEach(p => {
                window.canvas.remove(p)
            })
            if (curve.arrow) {
                window.canvas.remove(curve.arrow)
            }
            window.canvas.remove(curve)
        }
    })
    window.canvas.discardActiveObject().renderAll()
    window.canvas.requestRenderAll();
}

export const hideObject = () => {
    const els = window.canvas.getActiveObjects()
    els.forEach(x => {
        if (x.__parent && !x.curve) {
            return
        }
        if (!x.curve) {
            if (x.trajectory) {
                x.trajectory.set('visible', false)
                if (x.trajectory.control) {
                    x.trajectory.control.set('visible', false)
                }
            } 
            if (x._animations) {
                x._animations.hide = window.frame
            }
            if (x._objects) {
                window.canvas.getObjects().forEach(o => {
                    if (x.uuid == o.uuid) {
                        o.set("visible", false)
                    }
                })
            }
            x.set('visible', false)
        } else {
            const curve = x.curve
            curve.points && curve.points.forEach(p => {
                p.set('visible', false)
            })
            if (curve.arrow) {
                curve.arrow.set('visible', false)
            }
            curve.set('visible', false)
        }
    })
    window.canvas.discardActiveObject().renderAll()
    window.canvas.requestRenderAll();
}

export const copyObject = () => {
    let elements = window.canvas.getActiveObjects()
    window.canvas.discardActiveObject().renderAll()

    const curves = []
    elements.forEach(el => {
        let cloned = false
        if (el.curve) {
            curves.forEach(c => {
                if (c.uuid == el.curve.uuid) {
                    cloned = true
                }
            })
            if (!cloned) {
                const path = el.curve.path.copyWithin()
                const curve = new fabric.Path(path, {
                    fill: el.curve.fill, 
                    stroke: el.curve.stroke, 
                    objectCaching: false,
                    strokeWidth: el.curve.strokeWidth,
                    selectable: false,
                    _curve_: true,
                    strokeDashArray: el.curve.strokeDashArray,
                    points: [],
                    controls: {},
                    uuid: new Date().valueOf() + "_" + new Date().toLocaleString()
                });
                curve.path[0][1] += 10
                curve.path[0][2] += 10
                curve.path[1][1] += 10
                curve.path[1][2] += 10
                curve.path[1][3] += 10
                curve.path[1][4] += 10
                window.canvas.add(curve)
                if (el.curve.arrow) {
                    drawArrow(curve)
                }
                for(let i = 0; i < 3; i++) {
                    const control = createCircControl(curve, i)
                    window.canvas.add(control)
                }
                if (curve.arrow) {
                    curve.arrow.set({
                        angle: getAngle(
                            curve.points[1].left,
                            curve.points[1].top,
                            curve.points[2].left,
                            curve.points[2].top,
                        ),
                        left: curve.points[2].left + curve.points[2].radius,
                        top: curve.points[2].top + curve.points[2].radius,
                    })
                }
                
            }
        } else {
            el.clone(cloned => {
                cloned.set("uuid", new Date().valueOf() + "_" + new Date().toLocaleString())
                if (!cloned.curve) {
                    cloned.set("top", cloned.top + 10)
                    cloned.set("left", cloned.left + 10)
                    window.canvas.add(cloned)
                }
            })
        }
    })
    window.canvas.requestRenderAll();
}

export const getAngle = (x1, y1, x2, y2) => {
    var angle = 0, x, y;
    x = (x2 - x1);
    y = (y2 - y1);
    if (x === 0) {
        angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
        angle = (x > 0) ? 0 : Math.PI;
    } else {
        angle = (x < 0) ? Math.atan(y / x) + Math.PI:(y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }
    return (angle * 180 / Math.PI + 90);
}

export const drawTrajectory = (target, frame = window.frame, load = false) => {
    const animations = target._animations

    if (animations && (!target.notAnimate && !target._hidden && !target.curve)) {
        if (target.trajectory && (frame <= animations.start || !target.visible)) {
            target.trajectory.set({visible: false})
            target.trajectory.control && target.trajectory.control.set({visible: false})

            window.canvas.requestRenderAll()
            return
        }

        const anterior = Object.assign({}, animations[`f-${frame - 1}`])

        const P0 = {x: anterior.left + (target.width * target.scaleX / 2), y: anterior.top + (target.height * target.scaleY / 2)}
        const P2 = {x: target.left + (target.width * target.scaleX / 2), y: target.top + (target.height * target.scaleY / 2)}

        // Calcula o ponto intermediário
        let P1 = {
            x: (P0.x + P2.x) / 2, // Ponto médio entre P0 e P2
            y: (P0.y + P2.y) / 2
        };

        // ponto de controle
        let C = {
            x: 2 * P1.x - (P0.x + P2.x) / 2,
            y: 2 * P1.y - (P0.y + P2.y) / 2
        }

        if (load && target.trajectory && target.trajectory.control && target.trajectory.control.controlPoints[frame]) {
            C.x = target.trajectory.control.controlPoints[frame].x
            C.y = target.trajectory.control.controlPoints[frame].y

            P1.x = calculateBezierPoint(P0, C, P2).x
            P1.y = calculateBezierPoint(P0, C, P2).y
        }

        const pathArray = [
            ['M', P0.x, P0.y],  // Move para P0
            ['Q', C.x, C.y, P2.x, P2.y]  // Curva quadrática de controle C até P2
        ]
        
        if (target.trajectory) {
            target.trajectory.set({"path": pathArray, visible: true})
            const control = target.trajectory.control

            if (control) {
                control.set({
                    left: P1.x - ((control.radius + control.strokeWidth) * control.scaleX),
                    top: P1.y - ((control.radius + control.strokeWidth) * control.scaleY),
                    visible: true
                });

                !control.controlPoints[frame] && (control.controlPoints[frame] = {x: C.x, y: C.y});

                control.controlPoints[frame].x = C.x
                control.controlPoints[frame].y = C.y
            }
            window.canvas.requestRenderAll()
        } else {

            // Criar um novo path no fabric.js
            target.trajectory = new fabric.Path(pathArray, {
                stroke: 'rgba(0, 0, 0, 0.5)',
                strokeWidth: 3,
                strokeDashArray: [15, 10],
                fill: 'transparent',
                selectable: false,
                _hidden: true,
                notAnimate: true,
                objectCaching: false,
                __parent: target,
                hasControls: false,
                hasRotatingPoint: false,
                hasBorders: false
            })

            window.canvas.add(target.trajectory)
            
            // criar ponto de controle
            target.trajectory.control = new fabric.Circle({
                left: P1.x,
                top: P1.y,
                radius: 10,
                scaleX: 1,
                scaleY: 1,
                stroke: 'black',
                strokeWidth: 0,
                fill: 'black',
                selectable: true,
                objectCaching: false,
                notAnimate: true,
                _hidden: true,
                __parent: target.trajectory,
                hasControls: false,
                controls: {},
                controlPoints: {[frame]: {x: C.x, y: C.y}}
            })
    
            // Evento para acionar `fn` quando o círculo for movido
            target.trajectory.control.on('moving', function ({transform: {target: {__parent, left, top, scaleX, scaleY, radius, strokeWidth}}}) {
                __parent, left, top, scaleX, scaleY
                const path = __parent.path
                if (path) {
                    const P0 = { x: path[0][1], y: path[0][2] } // Ponto inicial da curva
                    const P2 = { x: path[1][3], y: path[1][4] } // Ponto final da curva
                    const P1 = { x: left + (radius + strokeWidth) * scaleX, y: top + (radius + strokeWidth) * scaleY} // O novo ponto exato na curva (onde o círculo está)

                    // Calcula o novo ponto de controle C
                    const c = {
                        x: 2 * P1.x - (P0.x + P2.x) / 2,
                        y: 2 * P1.y - (P0.y + P2.y) / 2
                    }

                    path[1][1] = c.x
                    path[1][2] = c.y

                    __parent.set({path: path})
                    let fr = window.frame
                    target.trajectory.control.controlPoints[fr] = {...c}
                    window.canvas.requestRenderAll()
                }

            })
            window.canvas.add(target.trajectory.control)

            window.canvas.requestRenderAll()
        }

    }
}

export const movingObject = (target, frame = window.frame) => {
    target
    frame
    // const animations = target._animations

    drawTrajectory(target, frame)
}

export const filterSelection = e => {
    if (!e.selected) return;

    if (e.selected.length > 1) {
        const allowedObjects = e.selected.filter(obj => (!obj.__parent && !obj._parent && obj.selectable));
    
        if (allowedObjects.length !== e.selected.length) {
            window.canvas.discardActiveObject(); // Remove seleção atual
            if (allowedObjects.length > 1) {
                // Criar um novo grupo apenas com os permitidos
                const selection = new fabric.ActiveSelection(allowedObjects, {canvas: window.canvas });
                window.canvas.setActiveObject(selection);
            } else if (allowedObjects.length == 1) {
                // Selecionar apenas um objeto
                window.canvas.setActiveObject(allowedObjects[0]);
            }
            window.canvas.requestRenderAll();
        }
    }
}

export const initCanvas = (_this) => {
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = 'yellow';
    fabric.Object.prototype.cornerSize = 20
    fabric.Object.prototype.isTripleClick = fabric.Object.prototype.isTripleClick || function () {
        return false; // Define um comportamento padrão
    };

    fabric.ActiveSelection.prototype.on("selection:cleared", () => {
        window.canvas._objects.forEach(obj => {
            movingObject(obj)
        })
    })
    fabric.Object.prototype.borderScaleFactor = scale
    window.canvas = new fabric.Canvas('canvas')
    window.canvas.selection = true
    window.canvas.selectionBorderColor = 'blue';
    window.canvas.selectionLineWidth = 5 * scale;
    window.canvas.on("object:moving", (e) => {
        const {target} = e.transform
        
        movingObject(target, _this.frame)
    })
    window.canvas.on('mouse:down', evt => { mouseDown(evt, _this) })
    window.canvas.on('mouse:up', () => { mouseUp(_this) })
    window.canvas.on('mouse:move', (evt, transform) => { 
        mouseMove(evt, transform, _this)
    })

    window.canvas.on("selection:created", e => filterSelection(e))
    window.canvas.on("selection:updated", e => filterSelection(e))
    window.canvas.requestRenderAll()

    // controladores
    // delete control
    const img = new Image()
    img.src = deleteImg
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16 * scale,
        offsetX: 14 * scale,
        cursorStyle: 'pointer',
        mouseUpHandler: deleteObject,
        render: renderIcon(img),
        cornerSize: 20 * scale
    });
    fabric.Textbox.prototype.controls.deleteControl = fabric.Object.prototype.controls.deleteControl
    // copy control
    const copy = new Image()
    copy.src = copyImg
    fabric.Object.prototype.controls.copyControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16 * scale,
        offsetX: 36 * scale,
        cursorStyle: 'pointer',
        mouseUpHandler: copyObject,
        render: renderIcon(copy),
        cornerSize: 20 * scale
    });
    fabric.Textbox.prototype.controls.copyControl = fabric.Object.prototype.controls.copyControl

    window.canvas.on('path:created', function(e) {
        e.path.set();
        window.canvas.renderAll();
    })
    // addShortCuts(window.canvas)
}

export const setBackImage = async (camp, esporte, canvas = window.canvas, call = () => {}) => {
    const imageUrl = `${Api.url}elements?path=${camp}`;
    esporte
    // Converter a imagem em Base64
    const toBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous"; // Permite o carregamento de imagens externas
            img.src = url;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL("image/png")); // Retorna a imagem como Base64
            };
            img.onerror = (err) => reject(err);
        });
    };

    try {
        // Obter a imagem em formato Base64
        const base64Image = await toBase64(imageUrl);

        // Criar uma nova instância da imagem do fabric.js
        new fabric.Image.fromURL(base64Image, (img) => {
            img.set({
                left: 0,
                top: 0,
                originX: "left",
                originY: "top",
            });

            img.scaleToHeight(canvas.height);
            img.scaleToWidth(canvas.width);

            // Definir a imagem redimensionada como background
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            call();
        });
    } catch (error) {
        console.error("Erro ao converter a imagem para Base64:", error);
    }
};

export const calculateControlPoint = (P0, P2, P1, t = 0.5) => {
    const oneMinusT = 1 - t;

    const C = {
        x: (P1.x - (oneMinusT ** 2) * P0.x - (t ** 2) * P2.x) / (2 * oneMinusT * t),
        y: (P1.y - (oneMinusT ** 2) * P0.y - (t ** 2) * P2.y) / (2 * oneMinusT * t)
    };

    return C;
}

export const calculateBezierPoint = (P0, C, P2, t = 0.5) => {
    const oneMinusT = 1 - t

    return {
        x: (oneMinusT ** 2) * P0.x + 2 * oneMinusT * t * C.x + (t ** 2) * P2.x,
        y: (oneMinusT ** 2) * P0.y + 2 * oneMinusT * t * C.y + (t ** 2) * P2.y
    }
}

export const bezierQuadratic = (P0, P1, P2, t) => {
    const oneMinusT = 1 - t;
    return {
        x: Math.pow(oneMinusT, 2) * P0.x +
           2 * oneMinusT * t * P1.x +
           Math.pow(t, 2) * P2.x,
        y: Math.pow(oneMinusT, 2) * P0.y +
           2 * oneMinusT * t * P1.y +
           Math.pow(t, 2) * P2.y,
    };
}

export const createCircControl = (curve, point) => {
    const options = [
        [[0,1], [0,2]],
        [[1,1], [1,2]],
        [[1,3], [1,4]],
    ]
    const option = options[point]
    const p = [].concat(curve.path)

    const c = new fabric.Circle({
        fill: curve.stroke,
        top: p[option[1][0]][option[1][1]] - (curve.strokeWidth + 5),
        left: p[option[0][0]][option[0][1]] - (curve.strokeWidth + 5),
        radius: (curve.strokeWidth + 5) * scale,
        curve: curve,
        opacity: 0.3,
        stroke: 'black',
        strokeWidth: curve.strokeWidth,
        controls: {},
        notAnimate: true,
        x1: null,
        y1: null,
        __parent: curve
    })
    curve.points.push(c)
    curve.notAnimate = true
    if (curve.arrow) {
        curve.arrow.notAnimate = true
    }
    c.on("moving", () => {
        if (point == 1) {
            const p0 = {
                x: p[options[0][0][0]][options[0][0][1]],
                y: p[options[0][1][0]][options[0][1][1]],
            }
            const p2 = {
                x: p[options[2][0][0]][options[2][0][1]],
                y: p[options[2][1][0]][options[2][1][1]],
            }
            const p1 = calculateControlPoint(p0, p2, {x: c.left + c.radius, y: c.top + c.radius})
            p[option[0][0]][option[0][1]] = p1.x
            p[option[1][0]][option[1][1]] = p1.y
        } else {
            p[option[0][0]][option[0][1]] = c.left + c.radius
            p[option[1][0]][option[1][1]] = c.top + c.radius
        }
        curve.set("path", p)
        if (curve.arrow) {
            curve.arrow.set({
                angle: getAngle(
                    curve.path[1][1],
                    curve.path[1][2],
                    curve.path[1][3],
                    curve.path[1][4],
                ),
                top: curve.points[2].top + c.radius,
                left: curve.points[2].left + c.radius,
            })
        }
        curve.setCoords()
    })
    c.controls.deleteControl = fabric.Object.prototype.controls.deleteControl
    c.controls.copyControl = fabric.Object.prototype.controls.copyControl
    return c
}

export const drawArrow = (curve) => {
    const triangle = new fabric.Triangle({
        originX: 'center',
        originY: 'center',
        selectable: false,
        type: (curve.points ? "pathArrow":"triangle"),
        width: (6 + curve.strokeWidth * 1.8) * scale,
        height: (6 + curve.strokeWidth * 1.8) * scale,
        fill: curve.stroke,
        _curve_: true
    });
    curve.arrow = triangle
    window.canvas.add(triangle)
    window.canvas.requestRenderAll()
}
