import { copyObject, deleteObject, movingObject } from "./DrawFunctions"
// import { fabric } from "fabric"
import { t } from "@/lang"


const move = (canvas, dir) => {
    const obj = canvas.getActiveObject()
    if (obj) {
        if (dir % 2 == 0) {
            obj.set('left', obj.left + 10 * (dir == 0 ? -1:1))
        } else {
            obj.set('top', obj.top + 10 * (dir == 1 ? -1:1))
        }
        // console.log(frame)
        movingObject(obj)

        obj.fire("moving", { target: obj, transform: {target: obj} });
    }
}

const scale = (canvas, signal = 1) => {
    const obj = canvas.getActiveObject()
    if (obj) {
        obj.scaleX += signal * 0.01
        obj.scaleY += signal * 0.01
    }
}

export const addShortCuts = (canvas) => {
    canvas.wrapperEl.addEventListener('keydown', e => {
        e.preventDefault()
        const key = e.keyCode
        if (e.altKey && e.ctrlKey) {
            // accoes de reversao
            switch (key) {
                case 65:
                    canvas.discardActiveObject()
                    break;
                default:
                    break;
            }
        } else if (e.ctrlKey) {
            // accoes de control
            switch (key) {
                case 67:
                    copyObject()
                    break;
                case 88:
                    deleteObject()
                    break
                // case 65:
                //     canvas.setActiveObject(new fabric.ActiveSelection(canvas.getObjects().filter(obj => !obj._curve_ && !obj.curve && !obj.isNotVisible()), {
                //         canvas: canvas,
                //     }))
                //     break
                case 107:
                    scale(canvas)
                    break
                case 109:
                case 189:
                    scale(canvas, -1)
                    break
                default:
                    break;
            }
            if (e.shiftKey && key == 187) {
                scale(canvas)
            }
        } else if (key >= 37 && key <= 40) {
            // dir
            move(canvas, key - 37)
        } else if (key == 46) {
            deleteObject()
        }
        canvas.requestRenderAll()
    })
}

export const shortCutsList = `
    [↑ ↓ → ←] : ${t("short-cuts.arrows")} \n
    [del] : ${t("short-cuts.del")} \n
    [cntrl + a] : ${t("short-cuts.cntrl_A")} \n
    [cntrl + c] : ${t("short-cuts.cntrl_C")} \n
    [cntrl + alt + a] : ${t("short-cuts.cntrl_alt_A")} \n
    [cntrl + +] : ${t("short-cuts.cntrl_+")} \n
    [cntrl + -] : ${t("short-cuts.cntrl_-")} \n
`
