<template>
    <select-campo-component v-if="sport" :esporte="sport" :show="true" @hide="back" @select="selectCampo"></select-campo-component>
</template>

<script>
    import SelectCampoComponent from '@/components/SelectCampoComponent.vue'
    import { mapMutations, mapActions } from 'vuex';
    export default {
        name: 'CampsView',
        components: {
            SelectCampoComponent
        },
        data() {
            return {
                esporte: null,
                video: null,
                sport: null
            }
        },
        async created() {
            this.esporte = this.$route.query.esporte
            this.video = this.$route.query.video || null

            this.SET_LOADING(true)

            try {
                const res = await this.get({url: `sports/${this.esporte}`})
                this.sport = res.data
            } catch (e) {
                alert("erro")
                this.back()
            }
            this.SET_LOADING(false)
        },
        methods: {
            ...mapActions(['get']),
            ...mapMutations(['SET_LOADING']),
            back() {
                this.$router.push(this.video ? "/videos":"/formacoes")
            },
            selectCampo(id) {
                this.$router.push(
                    `/marcar?esporte=${this.esporte}&camp=${id}${this.video ? "&video=true":""}`
                )
            }
        }
    }
</script>
