<template>
    <div class="col-12 p-0 m-0">
        <loader-component v-if="loading"/>
        <div class="login-box d-flex row justify-content-center m-auto" v-if="!user">
            <login-component></login-component>
        </div>
        <div class="wrapper" id="dash-board" v-else>
            <nav-bar :list="nav"/>

            <main-side-bar home_url="/" text="Planejador de Treino" :menu="menu"/>

            <main-content>
                <router-view v-show="!loading"></router-view>
            </main-content>

            <footer-component />

            <!-- Control Sidebar -->
            <aside class="control-sidebar control-sidebar-dark">
                <!-- Control sidebar content goes here -->
            </aside>
            <!-- /.control-sidebar --> 
        </div>
        <!-- Contacto -->
        <a href="https://wa.me/message/567MNUG3EA6JA1" class="btn btn-primary" style="position: fixed; bottom: 0.5em; right: 0.5rem; z-index: 1020">
            <i class="fa fa-phone"></i> Contacte-nos
        </a>
        <!-- Contacto --> 
    </div>
</template>

<script>
    import NavBar from './components/AdminLTE/DashBoard/NavBar.vue'
    // import logo from '@/assets/images/logo.jpg'
    import MainContent from './components/AdminLTE/DashBoard/MainContent.vue'
    import FooterComponent from './components/AdminLTE/Components/footer.vue'
    import MainSideBar from './components/AdminLTE/DashBoard/sidebar/MainSideBar.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import LoaderComponent from './components/LoaderComponent.vue'
    import LoginComponent from './components/auth/LoginComponent.vue'
    import { t } from './lang'

    export default {
        name: "App",
        data() {
            return {
                nav: [
                    {name: t('general.start'), url: "/"},
                    {name: t('general.sub'), url: "/payment"}
                ],
                logo: '/images/logo.jpg',
                menu: [
                    {selected: true, icon: "fas fa-home", name: t('general.start'), url: "/"},
                    {selected: false, icon: "fas fa-user", name: t('profile.my'), url: "/profile"},
                    {selected: false, icon: "", name: t('treinos.title'), url: "#",
                        menu: [
                            {selected: false, name: t('treinos.my'), url: "/treinos"},
                            {selected: false, name: t('treinos.create') + ' ' + t('treinos.title'), url: "/new-treino"},
                        ]
                    },
                    {selected: false, icon: "", name: t('exercices.formations'), url: "#",
                        menu: [
                            {selected: false, name: t('general.see'), url: "/formacoes"},
                        ]
                    },
                    {selected: false, icon: "", name: t('exercices.in-videos'), url: "#",
                        menu: [
                            {selected: false, name: t('exercices.videos'), url: "/videos"},
                        ]
                    },
                    {selected: false, icon: "", name: t('gifs.menu'), url: "#",
                        menu: [
                            {selected: false, name: t('gifs.list'), url: "/fitnes"},
                            {selected: false, name: t('treinos.create') + ' ' + t('gifs.menu'), url: "/new-fitnes"},
                        ]
                    },
                    {selected: false, icon: "", name: t('week.title'), url: "#",
                        menu: [
                            {selected: false, name: t('week.list'), url: "/weeks"},
                            {selected: false, name: t('week.create'), url: "/week"},
                        ]
                    },
                    // {selected: false, icon: "fas fa-edit", name: t('editable.editables'), url: "/edits"},

                    {selected: false, icon: "fas fa-user-users", name: t('teams.my'), url: "#", team: true,
                        menu: [
                            {selected: false, name: t('teams.team'), url: "/team/profile"},
                            {selected: false, name: t('teams.users'), url: "/team/users"},
                            {selected: false, name: t('teams.categories'), url: "/team/categories"},
                            {selected: false, name: t('teams.treinos'), url: "/team/treinos"},
                        ]
                    },
                    {selected: false, icon: "fas fa-user-edit", name: t('admin.admin-area'), url: "#", admin: true,
                        menu: [
                            {selected: false, name: t('admin.users'), url: "/admin/users"},
                            {selected: false, name: t('admin.plans'), url: "/admin/plans"},
                            {selected: false, name: t('teams.gerir'), url: "/admin/teams"},
                            {selected: false, name: t('clean.clean'), url: "/admin/clean"},
                            {selected: false, name: t('notify.notifys'), url: "/admin/notify"},
                            {selected: false, name: t('files.menu'), url: "/admin/files"},
                            {selected: false, name: t('new.newgifs'), url: "/admin/gifs"},
                        ]
                    },
                    {selected: false, icon: "fa fa-info", name: t('general.help'), url: "https://planejadordetreino.com/video", blank: true},
                    {selected: false, icon: "fas fa-users", name: t('general.community'), url: "https://planejadordetreino.com/comunidade-online/", blank: true},
                    {selected: false, icon: "fa fa-star", name: t('general.rate'), url: "https://planejadordetreino.com/sugestao-de-melhorias", blank: true},
                ]
            }
        },
        components: {
            LoginComponent,
            NavBar,
            MainContent,
            FooterComponent,
            MainSideBar,
            LoaderComponent
        },
        computed: {
            ...mapState(['user', 'loading', 'authPage'])
        },
        methods: {
            ...mapMutations(['SET_USER', 'SET_LOADING', 'SET_AUTH']),
            ...mapActions(['get', 'post', 'put', 'delete'])
        },
        watch: {
            $route(to) {
                if (!sessionStorage.getItem("auth_token")) {
                    this.SET_USER(null)
                } else {
                    switch(to.name) {
                        case 'login':
                            this.SET_USER(null)
                            this.SET_AUTH(1)
                            break
                        case 'signup':
                            this.SET_USER(null)
                            this.SET_AUTH(2)
                            break
                        case 'forget':
                            this.SET_USER(null)
                            this.SET_AUTH(3)
                            break
                        case 'changePass':
                            this.SET_USER(null)
                            this.SET_AUTH(4)
                            break
                        default:
                            this.menu.forEach(item => {
                                item.selected = false
                                if (item.menu) {
                                    item.menu.forEach(sub => {
                                        sub.selected = false;
                                        if (sub.url == to.path) {
                                            sub.selected = true
                                            item.selected = true
                                        }
                                    })
                                } else {
                                    if (item.url == to.path) {
                                        item.selected = true
                                    }
                                }
                            })
                            break
                    }
                }
            }
        },
    }
</script>

<style lang="scss">
    .datatable {
        padding: 0.2rem;

        .dataTables_wrapper {
            .dataTables_filter {
                text-align: right;
                label {
                    text-align: left;
                }
            }

            .dataTables_paginate {
                ul.pagination {
                    display: flex;
                    flex-direction: row;
                    align-content: end;
                    justify-content: end;
                }
            }
        }
    }
    .bottom-0 { bottom: 0; }
    .left-0 { left: 0; }
    .right-0 { right: 0; }
    .top-0 { top: 0; }
    .editable:not(:focus) {
        border: 0px;
        background-color: transparent;
    }
    .editable:focus {
        border: 1px solid gray !important;
    }
</style>
