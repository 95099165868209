<template>
    <div class="row m-0 p-2 col-12 justify-content-between">
        <div class="d-flex align-items-center justify-content-between col-12 p-2 m-0 bg-secondary" align-items-center>
            <div class="row m-0 p-0 col-auto align-items-center">
                <i class="fa fa-home" role="button" @click="goHome"></i>
                <template v-for="(dir, i) in stack" :key="dir">
                    <i class="fa fa-angle-right px-2"></i>
                    <label role="button" class="m-0 p-0" @click="() => goDir(i)">{{dir}}</label>
                </template>
            </div>

            <div class="row m-0 p-0 col-auto">
                <button @click="() => this.show = true" class="btn btn-primary mx-2">
                    <i class="fa fa-plus"></i>
                </button>

                <button class="btn btn-danger mx-2" @click="remove" :disabled="Object.keys(selected).length < 1">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>

        <div class="row flex-wrap justify-content-evenly m-0 p-2 col-12">
            <div role="button" v-for="({type, name, path}, i) in files" :key="i" @click="() => {(type == 'dir' ? go:select)(path)}" class="p-2 file-container col-4 col-md-3 col-lg-2" :class="{'bg-danger': selected[path]}">
                <img :src="type == 'dir' ? folderIcon:getFullPath(path)" class="col-12 img" />
                <div class="col-12 small p-0 m-0 text-center text-capitalize">{{name}}</div>
            </div>
        </div>

        <modal-component @hide="() => this.show = false" :show="show" :classList="['col-10 col-lg-9']" :title="t('files.add')">
            <div class="col-12 p-2">
                <input type="file" @change="changeFiles" multiple class="form-control my-1">
                <div class="row p-1 m-0 col-12">
                    <div v-for="(image, i) in images" :key="i" class="file-container p-2 col-4 col-md-3 col-lg-2">
                        <img :src="getObjectUrl(image)" class="col-12 img" />
                    </div>
                </div>
    
                <button v-if="images.length > 0" @click="send" class="btn btn-success my-2">
                    Enviar
                </button>
            </div>
        </modal-component>
    </div>
</template>

<script>
    import Api from '@/utilities/Api'
    import {mapActions, mapMutations} from 'vuex'
    import folderIcon from '../../assets/icons/folder.svg'
    import {t} from '../../lang/index.js'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'

    export default {
        name: 'FilesView',
        data() {
            return {
                path: '',
                files: [],
                folderIcon,
                selected: {},
                images: [],
                show: false,
            }
        },
        created() {
            document.title = "Files"
            this.goHome()
        },
        components: {
            ModalComponent
        },
        methods: {
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'post', 'delete']), t,
            getFullPath(path) {
                return `${Api.url}images/elements/${path}`
            },
            select(image) {
                if (this.selected[image]) {
                    delete this.selected[image]
                } else {
                    this.selected[image] = true
                }
            },
            go(dir) {
                this.path = dir
                this.selected = {}
                this.getPath(dir)
            },
            goHome() {
                this.go('')
            },
            goDir(index) {
                let fullPath = ''
                for (let i = 0; i <= index; i++) {
                    fullPath += this.stack[i] + '/'
                }
                
                console.log("Go to dir: ", this.stack[index], "Full: ", fullPath)
                this.go(fullPath)
            },

            async getPath(path = '') {
                this.SET_LOADING(true)
                this.files = []

                try {
                    const {data} = await this.get({url: 'admin/files?path=' + path})
                    this.files = data.files
                    console.log(data)
                } catch(e) {
                    alert("Ocorreu um erro buscando os arquivos")
                    console.log(e)
                } finally {
                    this.SET_LOADING(false)
                }
            },

            async remove() {
                const formData = new URLSearchParams()
                const images = Object.keys(this.selected)
                
                if (images.length < 1 || !confirm(this.t("general.do-you-want"))) {
                    return
                }
                for (let i = 0; i < images.length; i++) {
                    formData.append(`images[${i}]`, images[i])
                }

                this.SET_LOADING(true)
                console.log(formData.toString())

                const res = await this.delete({url: 'admin/files?' + formData.toString()})

                if (res.status >= 200 && res.status < 300) {
                    this.go(this.path)
                    alert(this.t("general.deleted"))
                } else {
                    console.log(res)
                    alert("Erro")
                }
                this.SET_LOADING(false)
            },
            changeFiles(event) {
                console.log(event.target.files)
                if (event.target && event.target.files) {
                    const files = event.target.files
                    if (files.length > 0) {
                        this.images = []
                        for (let i = 0; i < files.length; i++) {
                            this.images.push(files[i])
                        }
                    }
                }
            },
            getObjectUrl(blob) {
                return URL.createObjectURL(blob)
            },
            async send() {
                if (this.images.length > 0) {
                    this.SET_LOADING(true)
                    const formData = new FormData()
                    formData.append("path", this.path)
                    for (let i = 0; i < this.images.length; i++) {
                        formData.append(`images[${i}]`, this.images[i])
                    }

                    const res = await this.post({url: "admin/files", formData: true, data: formData})
                    
                    if (res.status >= 200 && res.status < 300) {
                        this.go(this.path)
                        this.images = []
                        console.log(res)
                        alert(t("files.added"))
                    }
                    this.show = false
                    this.SET_LOADING(false)
                } else {
                    return
                }
            },
        },

        computed: {
            stack() {
                console.log(this.path.split('/').filter(value => value.length > 0))
                return this.path.split('/').filter(value => value.length > 0)
            },
        }
    }
</script>

<style scoped lang="scss">
    .file-container {
        transition: all .3s ease-in-out;
        border-radius: 7px;
        &:hover {
            transform: scale(1.05);
            // background: rgba(200, 200, 200, 0.7);
        }
        .img {
            aspect-ratio: 1 / 1; /* Define a proporção 1:1 (quadrado) */
            object-fit: contain;
        }
    }
</style>
