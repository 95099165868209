<template>
    <modal-component :title="t('exercices.select_camp')" :id="id" :show="show" @hide="hide" :classList="['col-10']">
        <div class="row m-0 justify-content-start flex-wrap" v-if="esporte.files">
            <div class="campo p-4 col-4 col-sm-3 col-md-2" v-for="campo in esporte.files.campos" :key="campo">
                <img :src="getImage(campo)" class="col-12" @click="select(campo)">
            </div>
        </div>
    </modal-component>
</template>

<script>
    import ModalComponent from './AdminLTE/Components/ModalComponent.vue'
    import { t } from '@/lang'
    import { mapMutations, mapActions } from 'vuex'
    import Api from '@/utilities/Api'

    export default {
        name: "SelectCampoComponent",
        components: {
            ModalComponent
        },
        emits: ['select', 'hide'],
        props: {
            id: String,
            esporte: Object,
            show: Boolean
        },
        data() {
            return {
                campos: [],
                Api
            }
        },
        methods: {
            t,
            select(name) {
                this.$emit('select', name)
            },
            hide() {
                this.$emit('hide')
            },
            getImage(path) {
                return `${Api.url}elements?path=${path}`
            },
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get'])
        }
    }
</script>

<style lang="scss" scoped>
    img {
        cursor: pointer;
        &:hover {
            border: 2px dashed rgb(40, 50, 130);
        }
    }
</style>    
