import { fillData } from '@/utilities/HelpFunctions.js'
import { calculateBezierPoint } from './DrawFunctions'
import { fabric } from 'fabric'

const setPropos = (obj, props) => {
    if (!obj._curve_) {
        obj.set("left", props.left)
        obj.set("top", props.top)
    }
    obj.canvas.renderAll.bind(obj.canvas)
}

export const runAnimation = (obj, count, time, call) => {
    const animationKey = `f-${count}`
    // console.log(count)
    if (obj._animations.start == count) {
        // setar props
        const _props = obj._animations[animationKey]
        if (_props && _props.path) {
            obj.set("path", _props.path)
        }
        _props && setPropos(obj, _props)
        obj.set("visible", true)
        obj.canvas.requestRenderAll()
    }
    let animationProps = {
        left: 1
    }
    if (obj._animations[animationKey]) {
        animationProps = Object.assign({}, obj._animations[animationKey])
        // obj.set("visible", animationProps.visible)
        if (animationProps.path) {
            obj.set("path", animationProps.path),
            delete animationProps.path
        }
        obj.canvas.requestRenderAll()
        delete animationProps.visible
    }

    if (obj.curve && obj.curve.points) {
        obj.set("visible", false)
    } else if (obj.type == 'arrowPath') {
        animationProps = {
            // scaleX: obj.scaleX
        }
    }

    if (obj._hidden || obj._animations.hide <= count) {
        obj.set("visible", false)
    }

    // if (obj.type == 'image' && count > 1) {
    //     console.log(obj.left, obj.top)
    // }
    obj.animate(animationProps, {
        easing: fabric.util.ease.easeOutQuad,
        duration: time * 2.2,
        onChange(_, t) {
            if (obj.trajectory && obj.trajectory.control && obj.trajectory.control.controlPoints[count]) {
                if (typeof t !== "number" || isNaN(t)) {
                    t = 0 // Define um valor padrão
                }

                const C = obj.trajectory.control.controlPoints[count]
                const prevFrame = obj._animations[`f-${count - 1}`]
                const nextFrame = obj._animations[`f-${count}`]

                if (prevFrame && nextFrame) {
                    const P0 = { x: prevFrame.left, y: prevFrame.top }
                    const P2 = { x: nextFrame.left, y: nextFrame.top }

                    let xy = {x: 0, y: 0}

                    const mid = isMiddle(C, P0, P2, obj.width, obj.height, obj.scaleX, obj.scaleY)
                    if (mid) {
                        xy.x = P0.x + (P2.x - P0.x) * t
                        xy.y = P0.y + (P2.y - P0.y) * t
                    } else {
                        xy = {...calculateBezierPoint(P0, C, P2, t)}
                    }

                    obj.set({ left: xy.x, top: xy.y})
                    // obj.set({ left: x - ((radius + strokeWidth) * scaleX), top: y - ((radius + strokeWidth) * scaleY)})
                }
            }
            obj.canvas.renderAll.bind(obj.canvas)();
        },
        onComplete() {
            if (obj._animations.start <= count && !obj._animations[`f-${count + 1}`]) {
                call()
            } else {
                runAnimation(obj, count + 1, time, call);
            }
        }
    })
}

export const previwVideo = async (canvas, time = 1, save) => {
    window.sc = canvas
    return new Promise(resolve => {
        time = 1 / time
        time *= 1000
        // salvar o frame actual
        save()

        // run animations
        let count = 0;
        // window.video = null
        canvas.requestRenderAll()
        const recorder = new window.MediaRecorder(canvas.getElement().captureStream(), {
            mimeType: 'video/webm'
        })
        window.chuncks = []
        recorder.ondataavailable = e => {
            window.chuncks.push(e.data)
        }
        // recorder.start()
        if (canvas.getObjects().length == 0) {
            resolve()
            alert("Adicione pelo menos um objecto")
        } else {
            recorder.start()
            canvas.getObjects().map((obj) => {
                // setar props inicias e comecar animacoes a partir do 1
                if (obj._animations.start == 1 && !obj.notAnimate) {
                    // setar props
                    const _props = obj._animations[`f-1`]
                    obj.visible = _props.visible
                    setPropos(obj, _props)
                }
                // rodar da primeira animacao
                runAnimation(obj, 1, time, function() {
                    count++;
                    if (count >= canvas.getObjects().length) {
                        setTimeout(() => {
                            recorder.stop()
                            resolve()
                        }, time);
                    }
                })
            })
        }
    })
}

export const linearInterpolate = (initial, final, t) => initial + (final - initial) * t;

export const sendVideo = async (images, formData, post) => {
    images = window.chuncks
    const blob = new Blob(images)
   
    if (!blob) {
        return null
    }
    formData.append("file_input", blob, new Date().valueOf() + '.webm')
    const res = await post({
        url: 'formactions/',
        formData: true,
        data: formData
    })
    return res
}

export const saveVideo = async (ex, post/*, canvas, time*/) => {
    const formData = new FormData()

    formData.append("img", ex.img)
    delete ex.img
    delete ex.file
    if (!fillData(formData, ex)) {
        return null
    } else {
        formData.append('type', "video");
        return await sendVideo(window.video, formData, post)
    }
}

const isMiddle = (C, P0, P2, width, height, scaleX, scaleY) => (
    C.x == ((P0.x + (width * scaleX) / 2) + (P2.x + (width * scaleX) / 2)) / 2
    &&
    C.y == ((P0.y + (height * scaleX) / 2) + (P2.y + (height * scaleY) / 2)) / 2
)
