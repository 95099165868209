<template>
    <div class="row p-0 m-0">
        
        <button v-for="(path, id) in items" :key="id" style="width: 55px; height: 55px;">
            <img :src="getFullPath(path)" :id="['icon-' + id]" draggable="true" class="img" @drag="drag" @click="dblclick">
        </button>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { setDataTransfer } from '@/utilities/DropFunctions.js'
    import Api from '@/utilities/Api'

    export default {
        name: 'TextComponent',
        props: {
            items: Array
        },
        data() {
            return {
                Api
            }
        },
        emits: ['click'],
        async created() {
        },
        methods: {
            t,
            getFullPath(path) {
                return `${Api.url}elements?path=${path}`
            },
            drag(e) {
                setDataTransfer("image", e.target);
            },
            async dblclick(e) {
                setDataTransfer("image", e.target);
                this.$emit('click', e.target)
            }
        }
    }
</script>

<style lang="scss" scoped>
    button {
        padding: 2px;
        background-color: transparent;
        border: none;
        margin: 0px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>
