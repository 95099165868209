<template>
    <div class="col-12 p-4 row justify-content-center">
        <h2 class="title col-12">
            {{ t('gifs.new-ex') }}
        </h2>

        <div class="col-12 card card-outline card-primary p-2" :class="{'col-md-8': forms == 1, 'col-md-12': forms > 1}" v-if="!loading">
            <div class="row p-3 m-0 justify-content-center gap-3" v-if="forms <= 0">
                <div class="col-auto">
                    <button class="btn btn-primary" @click="bisetType">{{t('new.biset')}}</button>
                </div>
                <div class="col-auto">
                    <button class="btn btn-success" @click="() => this.forms = 1">{{t('new.normal')}}</button>
                </div>
            </div>
            <form @submit.prevent="submit" v-else class=" row flex-wrap col-12">
                <div class="col-12 p-2">
                    <label>{{t('exercices.title')}}</label>
                    <input type="text" v-model="treino.title" class="form-control" :placeholder="t('gifs.title')" required>
                </div>
                <div class="col-12 row m-0 p-0 gap-1" :class="{'col-md-12': forms == 1, 'col-md-6': forms > 1}" v-for="i in forms" :key="i">
                    <hr class="hr col-12" v-if="forms > 1" />
                    <h5 class="h5 text-bold col-12" v-if="forms > 1">{{t('new.ex' + i)}}</h5>
                    <div class="col-12 col-md-7">
                        <div class="px-2 col-12 pt-0">
                        <!-- duration	charge	series	group	 -->
                            <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                <input :id="'h_duration' + i" maxlength="20" class="edit col-10 border-none text-bold" :placeholder="t('gifs.duration')" v-model="fillVar(i)['h_duration']">
                                <label :for="'h_duration' + i" role="button" class="fa fa-edit"></label>
                            </div>
                            <input type="text" v-model="fillVar(i)['duration']" class="form-control" required :placeholder="t('gifs.duration')">
                        </div>
                        <div class="px-2 col-12 pt-0">
                            <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                <input :id="'h_charge' + i" :placeholder="t('gifs.charge')" maxlength="20" class="edit col-10 border-none text-bold" v-model="fillVar(i)['h_charge']">
                                <label :for="'h_charge' + 1" role="button" class="fa fa-edit"></label>
                            </div>
                            <input type="text" v-model="fillVar(i)['charge']" class="form-control" required :placeholder="t('gifs.charge')">
                        </div>
                        <div class="px-2 col-12 pt-0">
                            <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                <input :id="'h_series' + i" maxlength="20" class="edit col-10 border-none text-bold" :placeholder="t('treinos.exercices.series')" v-model="fillVar(i)['h_series']">
                                <label :for="'h_series' + i" role="button" class="fa fa-edit"></label>
                            </div>
                            <input type="text" v-model="fillVar(i)['series']" class="form-control" required :placeholder="t('treinos.exercices.series')">
                        </div>
                        <div class="px-2 col-12 pt-0">
                            <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                <input :id="'h_group' + i" maxlength="20" class="edit col-10 border-none text-bold" :placeholder="t('gifs.group')" v-model="fillVar(i)['h_group']">
                                <label :for="'h_group' + i" role="button" class="fa fa-edit"></label>
                            </div>
                            <input type="text" v-model="fillVar(i)['group']" class="form-control" required :placeholder="t('gifs.group')">
                        </div>
                    </div>
                    <div class="col-12 col-md-5 position-relative overflow-auto">
                        <img class="image-responsive responsive col-12" style="min-height: 3rem" :src="imgs[i]">
                        <button class="btn btn-warning position-absolute text-white bottom-0 right-0" type="button"
                            @click="() => openModal(i)"
                        >
                            <i class="fa fa-image"></i>
                        </button>
                    </div>
                    <div class="col-12">
                        <div class="col-12 p-2">
                            <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                <input :placeholder="t('treinos.description')" :id="'h_objectives' + i" maxlength="20" class="edit col-10 border-none text-bold" v-model="fillVar(i)['h_objectives']">
                                <label :for="'h_objectives' + i" role="button" class="fa fa-edit"></label>
                            </div>
                            <textarea class="form-control" rows="5" :name="'objetivos' + i" v-model="fillVar(i)['objectives']" maxlength="800"></textarea>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="py-2">
                        <button class="btn btn-success mx-2" type="submit">
                            {{ t('treinos.create') }}
                        </button>
                        <button class="btn btn-warning mx-2" @click="back" type="button">
                            <i class="fa fa-arrow-left mr-2"></i>
                            {{ t('general.back') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <modal-component @hide="() => this.showModal = false" :show="showModal" :classList="['col-12']" :title="t('gifs.select')">
            <select-gif-component @selectGif="path => {select(path)}"></select-gif-component>
        </modal-component>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import { t } from '@/lang'
    import Api from '@/utilities/Api'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import SelectGifComponent from '../../components/SelectGifComponent.vue'

    export default {
        name: 'NewTreinoView',
        data() {
            return {
                showModal: false,
                treino: {
                    title: '',

                    duration: '',
                    charge: '',
                    series: '',
                    group: '',	
                    objectives: '',

                    h_duration: t('gifs.duration'),
                    h_charge: t('gifs.charge'),
                    h_series: t('treinos.exercices.series'),
                    h_group: t('gifs.group'),
                    h_objectives: t('treinos.description'),
                    path: null,
                    fitnes_id: null,
                    child: null
                },
                selected: 1,
                forms: 0,
            }
        },
        components: {
            ModalComponent,
            SelectGifComponent
        },
        async created() {
            document.title = t('gifs.new-ex')
            await this.getTreino()
        },
        computed: {
            ...mapState(['loading']),
            imgs() {
                return {
                    1: `${Api.url}images/GIFS/${this.treino.path}`,
                    2: this.treino.child ? `${Api.url}images/GIFS/${this.treino.child.path}`:''
                }
            },
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']), t,
            back() {
                this.$router.push("/fitnes/" + this.treino.fitnes_id)
            },
            bisetType() {
                this.treino.child = {}
                for (let key in this.treino) {
                    if (key != 'child') {
                        this.treino.child[key] = this.treino[key]
                    }
                }
                this.forms = 2
            },
            select(path) {
                this.fillVar(this.selected).path = path
                this.showModal = false
            },
            async getTreino() {
                this.SET_LOADING(true)
                
                // const id = this.$route.params.id
                this.treino.fitnes_id = this.$route.params.id
                const response = await this.get({url: 'fitnesex/' + this.treino.fitnes_id})
                if (response.status == 200) {
                    // okay
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    this.$router.push('/login')
                } else if (response.status == 404) {
                    alert("Nao encontrado")
                    this.$router.push("/fitnes")
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            openModal(i) {
                this.selected = i
                this.showModal = true
            },
            fillVar(i) {
                return i == 1 ? this.treino:this.treino.child
            },
            async submit() {
                if (!this.treino.path || (this.treino.child && !this.treino.child.path)) {
                    alert(t('gifs.select'))
                    return null
                }
                this.SET_LOADING(true)

                const fields = {
                    'path': 'path', 'duration': 'duration', 'charge': 'charge',
                    'series': 'series', 'group': 'group', 'description': 'objectives',
                }

                const frmDt = new FormData()
                frmDt.append('title', this.treino.title)
                
                for (let key in fields) {
                    console.log(key)
                    frmDt.append(key, this.treino[fields[key]])
                    frmDt.append(`h_${key}`, this.treino[`h_${fields[key]}`])

                    if (this.treino.child) {
                        frmDt.append(`child[${key}]`, this.treino.child[fields[key]])
                        frmDt.append(`child[h_${key}]`, this.treino.child[`h_${fields[key]}`])
                    }
                }
                
                frmDt.append('fitnes_id', this.treino.fitnes_id)

                const response = await this.post({url: 'fitnesex', formData: frmDt, data: frmDt})
                console.log(response)
                if (response.status == 200) {
                    alert(t('gifs.created'))
                    this.$router.push('/fitnes/' + this.treino.fitnes_id)
                } else {
                    alert(response.data.message)
                }
                console.log(response)
                this.SET_LOADING(false)
            }
        },
    }
</script>

<style lang="scss" scoped>
   
</style>
