<template>
    <div class="row p-0 m-0">
        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
            <select class="form-control" v-model="equipe">
                <option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</option>
            </select>
        </div>
        <button v-for="(path, id) in jogadores[equipe.toUpperCase()]" :key="id" style="width: 55px; height: 55px;">
            <img :src="getFullPath(path)" :id="['player-' + id]" draggable="true" class="img" @drag="drag" @click="dblclick">
        </button>
    </div>
</template>

<script>
    import { t } from '@/lang'
    import { setDataTransfer } from '@/utilities/DropFunctions.js'
    import Api from '@/utilities/Api'

    export default {
        name: 'PlayersComponent',
        emits: ['click'],
        props: {
            jogadores: Object,
        },
        data() {
            return {
                Api,
                options: [
                    {value: 'AMARELA', text: t('players.yellow')},
                    {value: 'AZUL', text: t('players.blue')},
                    {value: 'VERDE', text: t('players.green')},
                    {value: 'VERMELHA', text: t('players.red')},
                    {value: 'PRETA', text: t('players.black')},
                ],
                equipe: ''
            }
        },
        async created() {
            this.equipe = this.options[0].value
        },
        computed: {
            color() {
                return this.equipe.toUpperCase()
            },
        },
        methods: {
            t,
            drag(e) {
                setDataTransfer("image", e.target);
            },
            async dblclick(e) {
                setDataTransfer("image", e.target);
                this.$emit('click', e.target)
            },
            getFullPath(path) {
                return `${Api.url}elements?path=${path}`
            },
        },
    }
</script>

<style lang="scss" scoped>
    button {
        padding: 2px;
        background-color: transparent;
        border: none;
        margin: 0px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>
