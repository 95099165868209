<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 com-md-11 col-sm-9" v-if="treino">
            <div class="card card-primary">
                <!-- card-header -->
                <div class="card-header">
                    <h3 class="card-title">{{ t('treinos.details') }}</h3>

                    <!-- card-tools -->
                    <div class="card-tools">    
                        <button type="button" class="btn btn-tool" data-card-widget="maximize">
                            <i class="fas fa-expand"></i>
                        </button>
                    </div>
                    <!-- /.card-tools -->
                </div>
                <!-- /.card-header -->

                <form @submit.prevent="update">
                    <!-- card-body -->
                    <div class="card-body">
                        <div class="col-12 row m-0 p-1 justify-content-around">
                            <div class="col-12">
                                <div class="form-control disabled" v-if="treino.category">{{ treino.category.team.name }}</div>
                                <input v-else class="form-control" required :placeholder="t('treino.coach')" v-model="treino.clube">
                            </div>
                            <div class="col-12 col-sm-5 position-relative p-2">
                                <input type="file" ref="file" class="d-none" @change="changeFile">
                                <img class="image-responsive responsive col-12" :src="treino.img_url">
                                <button v-if="!treino.category" type="button" class="btn btn-secondary position-absolute text-white upload-btn" id="upload-btn" @click="() => {
                                    this.$refs.file.click()
                                }" :class="{'d-none': !treino.updatable}">
                                    <i class="fa fa-upload"></i>
                                </button>
                                
                            </div>
                            <div class="col-12 col-sm-7">
                                <div v-for="input in inputs" :key="input.label" class="col-12 p-0 m-0 py-1">
                                    <label>{{ input.label }}</label>
                                    <template v-if="treino.category && (input.model == 'categoria' || input.model == 'professor')">
                                        <div class="form-control" v-if="input.model == 'categoria'">{{ treino.category.name }}</div>
                                        <div class="form-control" v-if="input.model == 'professor'">{{ treino.user.name }}</div>
                                    </template>
                                    <input v-else :disabled="!treino.updatable" required class="form-control" :type="input.type" :placeholder="input.label" v-model="treino[input.model]">
                                </div>
                            </div>
                            <form-group-component :label="t('treinos.objectives')" class="col-12 pt-0">
                                <textarea :disabled="!treino.updatable" required rows="3" class="form-control" v-model="treino.objetivos" maxlength="800"></textarea>
                            </form-group-component>
                        </div>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <button class="btn btn-primary" :class="{'d-none': !treino.updatable}">
                            {{ t('general.update_data') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 com-md-11 col-sm-9" v-if="treino">
            <div class="card collapsed-card card-primary">
                <!-- card-header -->
                <div class="card-header">
                    <h3 class="card-title">{{ t('post-work.title') }}</h3>

                    <!-- card-tools -->
                    <div class="card-tools">    
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>
                    <!-- /.card-tools -->
                </div>
                <!-- /.card-header -->

                
                <!-- card-body -->
                <div class="card-body">
                    <div class="col-12 row m-0 p-1 justify-content-around">
                        
                        <form-group-component :label="t('post-work.header')" class="col-12 pt-0">
                            <input type="text" class="form-control" v-model="treino.postwork.header" name="header"  />
                        </form-group-component>
                        <form-group-component :label="t('post-work.details')" class="col-12 pt-0">
                            <ckeditor
                                v-model="treino.postwork.description"
                                :editor="editor"
                                :config="editorConfig"
                            />
                        </form-group-component>
                    </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer" @click='sendRate'>
                    <button class="btn btn-primary">
                        {{ t('general.update_data') }}
                    </button>
                </div>
            </div>
        </div>
        <select-campo-component 
            v-if="treino && treino.esporte && onSelect" 
            @select="selectCampo" 
            :esporte="sport"
            :show="true"
            @hide="() => { this.onSelect = false}"
        ></select-campo-component>

        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('exercices.of') }}</h3>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <div class='icheck-primary'>
                    <input type="checkbox" v-model="postwork" name='printpostwork' id="printpostwork">
                    <label for='printpostwork'>{{ t('datatable.print') }} {{ t('post-work.title') }}</label>
                </div>
                <button class="btn btn-flat btn-success" @click="print">
                    <i class="fa fa-print"></i> {{ t('datatable.print') }}
                </button>
                <button class="btn btn-flat btn-primary" @click="() => this.getPrintEx().length > 0 && (this.showCopy = true)" :class="{'d-none': treino && !treino.updatable}">
                    <i class="fa fa-copy"></i> {{ t('copy.copy') }}
                </button>
                
                <button class="btn btn-flat btn-warning" @click="() => { this.onSelect = true;}" :class="{'d-none': treino && !treino.updatable}">
                    <i class="fa fa-plus"></i> {{ t('datatable.add') }}
                </button>
                
                <data-table v-if="treino" class="table table-striped table-bordered" :data="exercicios" :columns="columns"
                    style="min-width: 100%;"
                    :options="{responsive: false, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>
                            </th>
                            <th>{{ t('general.actions') }}</th>
                            <th>{{ t('general.title') }}</th>
                            <th>{{ t('treinos.description') }}</th>
                            <th>{{ t('exercices.at') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <!-- Modals dos exercicios -->
        <modal-component :classList="['col-10', 'col-md-7']" v-if="selected" :title="selected.title"
            @hide="() => {this.selected = null}"
            :show="true"
        >
            <form ref="form" @submit.prevent="updateEx" class="row p-1 col-12">
                <div class="p-1 form-group m-0 py-2" v-for="input in exInputs" :key="input.model" :class="input.classe">
                    <span class="m-0 col-12 p-0 py-1 row justify-content-between" v-if="input.hmodel">
                        <input :id="['label_' + input.model]" class="editable col-10" v-model="selected[input.hmodel]" contenteditable>
                        <i class="fa fa-edit mx-2" style="cursor: pointer" @click="edit('label_'+input.model)"></i>
                    </span>
                    <span class="m-0" v-else>{{ input.label }}</span>
                    <input type="text" :placeholder="input.ex" :name="input.model" v-model="selected[input.model]" class="form-control" required>
                </div>

                <div class="p-1 form-group m-0 py-2">
                    <span for="descricao">{{ t('exercices.description') }}</span>
                    <textarea class="form-control" rows="4" maxlength="800" required v-model="selected.descricao"></textarea>
                </div>

                <div class="p-1 form-group m-0 py-2">
                    <button type="submit" class="btn btn-primary">{{ t('general.update_data') }}</button>
                </div>
            </form>
        </modal-component>

        <modal-component :classList="['col-11', 'col-sm-9', 'col-lg-7', 'p-1']" v-if="view"
            @hide="() => {this.view = null}"
            :show="true"
        >
            <img :src="view" class="col-12">
        </modal-component>
        <!-- ./Modals dos exercicios -->

        <!-- Modal copiar exercicio para outro treino -->
        <modal-component :classList="['col-11', 'col-md-7']" v-if="showCopy" :title="t('general.copyTo')"
            @hide="() => {this.showCopy = false}"
            :show="true"
        >
            <div class="col-12 p-2">
                <form-group-component :label="t('general.copyDest')">
                    <select v-model="treinoTo" class="form-control">
                        <option v-for="t in treinos" :value="t.id" :key="t.id">
                            [{{t.id}}] {{ t.clube }} - {{ t.professor }}
                        </option>
                    </select>
                </form-group-component>
                <form-group-component>
                    <button class="btn btn-success" @click="() => { copy(this.treinoTo) }">{{ t("datatable.copy") }}</button>
                </form-group-component>
            </div>
        </modal-component>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'
    import SelectCampoComponent from '@/components/SelectCampoComponent.vue'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    import { deleteExercice, decodeEntities, updateExercice, getExImage, fillData } from '@/utilities/HelpFunctions.js'
    import { check, exercicesToPrint } from '@/utilities/print.js'
    import api from '@/utilities/Api.js'
    import { ClassicEditor, Bold, Essentials, FontSize, FontSizeEditing, Italic, Mention, Paragraph, Undo, FontColorEditing, Alignment, Subscript, Superscript, FontColor, FontBackgroundColor} from 'ckeditor5';
    import 'ckeditor5/ckeditor5.css';

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "FormacoesView",
        components: {
            DataTable,
            ModalComponent,
            FormGroupComponent,
            SelectCampoComponent,
        },
        data() {
            return {
                treinos: [],
                sport: null,
                showCopy: false,
                postwork: false,
                treinoTo: null,
                exercicios: [],
                showModal: false,
                copyTo: null,
                treino: null,
                printNow: false,
                onSelect: false,
                url: 'formactions',
                editor: ClassicEditor,
                editorConfig: {
                    plugins: [
                        Essentials,
                        Bold,
                        Paragraph,
                        Italic,
                        Mention,
                        Undo,
                        FontColorEditing,
                        Alignment,
                        Subscript,
                        Superscript,
                        FontColor,
                        FontBackgroundColor,
                        FontSize,
                        FontSizeEditing
                    ],

                    fontSize: {
                        options: [16, 12, 14, 18, 20, 22, 24]
                    },
                    toolbar: ['undo', 'redo', '|', 'fontSize', '|', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'alignment', '|', 'subscript', 'superscript'],
                },
                inputs: [
                    {label: t('treinos.coach'), type: "text", model: 'professor'},
                    {label: t('treinos.category'), type: "text", model: 'categoria'},
                    {label: t('treinos.date'), type: "date", model: 'data'},
                    {label: t('treinos.metodology'), type: "text", model: 'metodologia'}
                ],
                exInputs: [
                    {name: 'title', model: 'titulo', text: t('exercices.title'), classe: 'col-12', ex: '', header: false},
                    {name: 'duration', model: 'duracao', text: t('exercices.total_duration'), classe: 'col-6', ex: '', hmodel: 'h_duracao', header: true},
                    {name: 'blocks', model: 'blocos_de_duracao', text: t('treinos.exercices.blocks'), classe: 'col-6', ex: ``, hmodel: 'h_blocos', header: true},
                    {name: 'demension', model: 'dimensao', text: t('treinos.exercices.dimensions'), classe: 'col-6', ex: ``, hmodel: 'h_dimensao', header: true},
                    {name: 'realation', model: 'relacao_numerica', text: t('treinos.exercices.relation'), classe: 'col-6', ex: ``, hmodel: 'h_relacao', header: true},
                ],
                campos: [],
                selected: null,
                view: null,
                newImg: null,
            }
        },
        created() {
            document.title = "Formacoes tacticas"
            if (/^\/treino\/\d+/.test(this.$route.path)) {
                this.url = `treinos/${this.$route.params.id}`
            }
            this.getExercices()
        },
        
        computed: {
            ...mapState(['loading']),
            columns() {
                return [
                    {data: 'id'},
                    {data: null, render: data =>  `
                        <div class='icheck-primary'>
                            <input type="checkbox" name='check${data.id}' id="check${data.id}" data-id='${data.id}' data-action='check'>
                            <label for='check${data.id}'></label>
                        </div>`
                    },
                    {data: null, render: data => `
                        <div class="row p-0 m-0 flex-nowrap">
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-primary' data-id='${data.id}' data-action='view'>
                                    <i class='fa fa-eye' data-id='${data.id}' data-action='view'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-warning ${this.treino.updatable ? "":"d-none"}' data-id='${data.id}' data-action='edit'>
                                    <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-danger ${this.treino.updatable ? "":"d-none"}' data-id='${data.id}' data-action='delete'>
                                    <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                                </button>    
                            </div>
                        </div>`
                    },
                    {data: 'titulo'},
                    {data: null, render: data => `${data.descricao.substr(0, 40)}...`},
                    {data: null, render: data => `${new Date(data.created_at).toLocaleString()}`}
                ]
            },
        },
        methods: {
            ...mapMutations(['SET_CAMPO', 'SET_LOADING']),
            ...mapActions(['get', 'put', 'delete', 'post']),
            decodeEntities,
            async getExercices() {
                this.SET_LOADING(true)

                const response = await this.get({
                    url: this.url
                })
                
                if (response.status != 200) {
                    alert(response.data.message)
                    this.$router.push("/treinos")
                } else {
                    this.treino = response.data.treino
                    console.log(this.treino)
                    this.treinos = response.data.treinos
                    // console.log(this.treinos)
                    if (this.treino) {
                        this.exercicios = this.treino.exercicios
                        document.title = "Exercicios"
                        delete this.treino.exercicios
                        // decodeEntities
                        const excludes = ['esporte', 'category', 'user', 'updatable', 'postwork']
                        for(const key in this.treino) {
                            if (!excludes.includes(key)) {
                                this.treino[key] = decodeEntities(this.treino[key])
                            }
                        }
                        !this.treino.postwork.header && (this.treino.postwork.header = t('post-work.title'));

                        console.log(this.treino.postwork)
                        // image
                        const rsp = await this.get({url: 'treinoimg/' + this.treino.id, type: 'blob'})
                        // console.log(rsp)
                        this.treino.img = new Blob([rsp.data], { type: 'image/png' })
                        const url = URL.createObjectURL(this.treino.img)
                        this.treino.img_url = url

                        // esporte
                        const esp = await this.get({url: `sports/${this.treino.esporte_id}`})
                        this.sport = esp.data
                    } else {
                        this.exercicios = response.data.formacoes
                    }
                }
                this.SET_LOADING(false)
                if (!this.loading && this.$el.querySelector('#my-table')) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }

                check(null, [])
            },
            async handleClick(event) {
                if (event.target.hasAttribute("data-action")) {
                    const defaults = {
                        h_duracao: t('exercices.total_duration'),
                        h_blocos: t('treinos.exercices.blocks'),
                        h_dimensao: t('treinos.exercices.dimensions'),
                        h_relacao: t('treinos.exercices.relation')
                    }
                    let [exercice] = this.exercicios.filter(ex => ex.id == event.target.getAttribute("data-id"))
                    switch(event.target.getAttribute("data-action")) {
                        case 'view':
                            this.SET_LOADING(true)
                            this.view = await getExImage(exercice.id, this.get)
                            this.SET_LOADING(false)
                            break
                        case 'edit':
                            // decode entities
                            exercice = Object.assign({}, exercice)
                            for(const key in exercice) {
                                if (key.startsWith('h_')) {
                                    if (!exercice[key] || exercice[key].length <= 0) {
                                        exercice[key] = defaults[key]
                                    }
                                }
                                exercice[key] = decodeEntities(exercice[key])
                            }
                            this.selected = exercice
                            break
                        case 'delete':
                            // confirm delete
                            if (confirm("Deseja realmente deletar esta Exercicio?")) {
                                this.SET_LOADING(true)
                                await deleteExercice(exercice.id, this.delete)
                                await this.getExercices()
                                this.SET_LOADING(false)
                            }
                            break
                        case 'check':
                            // exercice.check = event.target.checked
                            check(exercice)
                            break
                    }
                }
            },
            changeFile(event) {
                const [img] = event.target.files
                if (img) {
                    this.treino.img = img
                    this.treino.img_url = URL.createObjectURL(img)
                    this.newImg = img
                }
            },
            selectCampo(id) {
                this.$router.push(`/marcar/${this.treino.id}?camp=${id}`)
            },
            t,
            async update() {
                this.SET_LOADING(true)

                const formData = new FormData()
                
                const copy = Object.assign({}, this.treino)
                copy.img = "0"
                copy.esporte = "0"
                if (!fillData(formData, copy)) {
                    alert("Preencha todos os campos")
                    return null
                }

                if (this.newImg) {
                    console.log(this.newImg)
                    formData.append("img", this.newImg);
                }
                const res = await this.post({
                    url: 'treinos/' + this.treino.id,
                    data: formData,
                    formData: true
                })

                if (res.status < 200 || res.status > 299) {
                    alert("Ocorreu um erro")
                } else {
                    alert("Actualizado com sucesso")
                    await this.getExercices()
                }

                this.SET_LOADING(false)
            },
            async updateEx({target}) {
                if(target.checkValidity()) {
                    this.SET_LOADING(true)
                    const res = await updateExercice(this.selected, this.put)
                    console.log(res)
                    await this.getExercices()
                    this.selected = null
                    this.SET_LOADING(false)
                }
            },
            getPrintEx() {
                return exercicesToPrint()
            },
            print() {
                if (this.getPrintEx().length < 1) {
                    return null
                }
                const p = new URLSearchParams()
                p.append("treino", this.$route.params.id)
                let i = 0
                for(let ex of this.getPrintEx()) {
                   p.append(`exs[${i}]`, ex.id) 
                   i++
                }
                const lang = localStorage.getItem("lang_code") ? localStorage.getItem("lang_code").substring(0, 2).substring(0, 2):"pt"
                p.append("auth_token", sessionStorage.getItem("auth_token"))
                p.append("lang", lang)
                if (this.postwork) {
                    p.append('postwork', 1)
                }
                console.log(p.toString())
                open(api.printUrl + "/ex?" + p.toString(), "_blank")
            },
            async copy(copyTo) {
                this.showCopy = false
                if (this.getPrintEx().length < 1) {
                    return null
                }
                this.SET_LOADING(true)
                const exs = this.getPrintEx()
                const formData = new FormData();
                console.log(exs)
                for(let i = 0; i < exs.length; i++) {
                    formData.append(`exercices[${i}]`, exs[i].id);                    
                }
                formData.append("to", copyTo)
                const res = await this.post({
                    url: 'exercicios/copy/' + copyTo,
                    data: formData,
                    formData: true
                })
                if (!res || (res.status < 200 || res.status > 299)) {
                    alert("Ocorreu um erro")
                    console.log(res)
                } else {
                    alert(t("general.copy"))
                    await this.getExercices()
                }
                this.copyTo = false
                this.SET_LOADING(false)
            },
            edit(id) {
                this.$el.querySelector(`#${id}`).focus()
            },
            async sendRate() {
                this.SET_LOADING(true)
                const res = await this.post({
                    url: 'rate/' + this.treino.id,
                    data: this.treino.postwork,
                })

                console.log(res)
                this.SET_LOADING(false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
    .upload-btn {
        bottom: 0px;
        right: 0px;
    }

    [lineHeight="1"] {
        margin-bottom: 0.5rem;
    }
    [lineHeight="1.5"] {
        line-height: 1.5rem;
    }
    [lineHeight="2"] {
        line-height: 2rem;
    }
    [lineHeight="2.5"] {
        line-height: 2.5rem;
    }
</style>
