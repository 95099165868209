<template>
    <div class="col-12 p-4 row justify-content-center">
        <h2 class="title col-12">
            {{ t('gifs.new') }}
        </h2>

        <div class="col-12 col-md-8 card card-outline card-primary p-2" v-if="!loading">
            <form @submit.prevent="submit" class=" row flex-wrap col-12">
                <div class="col-12 p-2">
                    <label>{{t('general.title')}}</label>
                    <input type="text" v-model="treino.title" class="form-control" :placeholder="t('gifs.title')" required>
                </div>
                <div class="col-12 col-md-7">
                    <div class="p-2 col-12">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_teacher" :placeholder="t('treinos.prof')" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_teacher">
                            <label for="h_teacher" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="text" v-model="treino.teacher" class="form-control" required :placeholder="t('treinos.coach')">
                    </div>
                    <div class="p-2 col-12">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_volume" placeholder="Volume" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_volume">
                            <label for="h_volume" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="text" v-model="treino.volume" class="form-control" required :placeholder="t('gifs.volume')">
                    </div>
                    <div class="p-2 col-12">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_student" :placeholder="t('gifs.student')" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_student">
                            <label for="h_student" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="text" v-model="treino.student" class="form-control" required :placeholder="t('gifs.student')">
                    </div>
                </div>
                <div class="col-12 col-md-5 position-relative overflow-auto">
                    <input type="file" ref="file" class="d-none" @change="changeFile">
                    <img class="image-responsive responsive col-12" style="min-height: 3rem" :src="img">
                    <button class="btn btn-warning position-absolute text-white bottom-0 right-0" type="button"
                        @click="() => { this.$refs.file.click() }"
                    >
                        <i class="fa fa-upload"></i>
                    </button>
                </div>
                <div class="col-12">
                    <div class="p-2 col-12">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_data" :placeholder="t('treinos.date')" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_data">
                            <label for="h_data" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="date" v-model="treino.data" class="form-control" placeholder="Data" required>
                    </div>
                    <div class="col-12 p-2">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_objectives" :placeholder="t('treinos.objectives')" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_objectives">
                            <label for="h_objectives" role="button" class="fa fa-edit"></label>
                        </div>
                        <textarea required class="form-control" rows="5" name="objetivos" v-model="treino.description" maxlength="800"></textarea>
                    </div>
                    <div class="py-2">
                        <button class="btn btn-primary" type="submit">
                            {{ t('treinos.create') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import { t } from '@/lang'

    export default {
        name: 'NewTreinoView',
        data() {
            return {
                user: null,
                team: -1,
                sports: [],
                img: null,
                treino: {
                    title: '',

                    teacher: '',
                    student: '',
                    data: '',
                    volume: '',	
                    objectives: '',

                    h_teacher: t('treinos.prof'),
                    h_student: t('gifs.student'),
                    h_data: t('treinos.date'),
                    h_volume: 'Volume',
                    h_objectives: t('treinos.objectives'),
                }
            }
        },
        async created() {
            document.title = t('treinos.new')
            await this.getUser()
        },
        computed: {
            ...mapState(['loading']),
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']),
            async getUser() {
                this.SET_LOADING(true)
                
                const response = await this.get({url: 'user'})
                if (response.status == 200) {
                    this.user = response.data.user
                    console.log(this.user)
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    this.$router.push('/login')
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)
            },
            t,
            changeFile(event) {
                this.treino.img = event.target.files[0]
                this.img = URL.createObjectURL(event.target.files[0])
            },
            async submit() {
                this.SET_LOADING(true)

                const frmDt = new FormData()
                frmDt.append('title', this.treino.title)
                frmDt.append('teacher', this.treino.teacher)
                frmDt.append('student', this.treino.student)
                frmDt.append('volume', this.treino.volume)
                frmDt.append('data', this.treino.data)
                frmDt.append('objectives', this.treino.objectives)
                frmDt.append('img', this.treino.img)
                
                frmDt.append('h_teacher', this.treino.h_teacher)
                frmDt.append('h_student', this.treino.h_student)
                frmDt.append('h_volume', this.treino.h_volume)
                frmDt.append('h_data', this.treino.h_data)
                frmDt.append('h_objectives', this.treino.h_objectives)

                const response = await this.post({url: 'fitnes', formData: frmDt, data: this.treino})
                if (response.status == 200) {
                    alert(t('treinos.created'))
                    this.$router.push('/fitnes/' + response.data.fitnes.id)
                } else {
                    alert(response.data.message)
                }
                console.log(response)
                this.SET_LOADING(false)
            }
        },
    }
</script>

<style lang="scss" scoped>
   
</style>
