<template>
    <modal-component :title="t('exercices.select_esporte')" :id="id" :show="show" @hide="hide" :classList="['col-10']">
        <div class="row m-0 justify-content-start flex-wrap" v-if="!loading">
            <div class="campo p-4 col-4 col-sm-3" v-for="sport in esportes" :key="sport.id">
                <img :src="sport.img" class="col-12" @click="select(sport.id)">
            </div>
        </div>
    </modal-component>
</template>

<script>
    import ModalComponent from './AdminLTE/Components/ModalComponent.vue'
    import { t } from '@/lang'
    import Api from '../utilities/Api.js'
    import { mapMutations, mapState, mapActions } from 'vuex'

    export default {
        name: "SelectEsporteComponent",
        components: {
            ModalComponent
        },
        emits: ['select', 'hide'],
        props: {
            id: String,
            show: Boolean
        },
        data() {
            return {
                esportes: []
            }
        },
        computed: {
            ...mapState(['loading'])
        },
        async created() {
            this.SET_LOADING(true)
            // obter esportes da api
            const response = await this.get({
                url: 'sports'
            })
            this.esportes = response.data.sports

            // obter imagns
            for(const sport of this.esportes) {
                sport.img = `${Api.url}elements?path=${sport.name.toUpperCase()}/${sport.name.toUpperCase()}.png`
            }
            this.SET_LOADING(false)
        },
        methods: {
            t,
            select(name) {
                this.$emit('select', name)
            },
            hide() {
                this.$emit('hide')
            },
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get'])
        }
    }
</script>

<style lang="scss" scoped>
    img {
        cursor: pointer;
        &:hover {
            border: 2px dashed rgb(40, 50, 130);
        }
    }
</style>    
