<template>
    <div class="row m-0 p-2 col-12 justify-content-between">
        <div class="d-flex align-items-center justify-content-between col-12 p-2 m-0 bg-secondary" align-items-center>
            <div class="row m-0 p-0 col-auto align-items-center">
                <i class="fa fa-home" role="button" @click="goHome"></i>
                <template v-for="(dir, i) in stack" :key="dir">
                    <i class="fa fa-angle-right px-2"></i>
                    <label role="button" class="m-0 p-0" @click="() => goDir(i)">{{dir}}</label>
                </template>
            </div>

            <div class="row m-0 p-0 col-auto">
                <button @click="() => this.newFolder = true" class="btn btn-primary mx-2">
                    <i class="fa fa-folder-plus"></i>
                </button>

                <button @click="() => this.show = true" class="btn btn-primary mx-2">
                    <i class="fa fa-plus"></i>
                </button>

                <button class="btn btn-danger mx-2" @click="remove" :disabled="Object.keys(selected).length < 1">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>

        <div class="row flex-wrap justify-content-evenly m-0 p-2 col-12">
            <div role="button" v-for="({type, name, path}, i) in files" :key="i" class="p-2 file-container col-4 col-md-3 col-lg-2" :class="{'bg-danger': selected[path]}">
                <img :src="type == 'dir' ? folderIcon:getFullPath(path)" class="col-12 img" @click="() => {(type == 'dir' ? go:select)(path)}" />
                <div class="col-12 small p-0 m-0 text-center text-capitalize" @click="() => {(type == 'dir' ? go:select)(path)}">{{name}}</div>
                <i v-if="type == 'dir'" @click="() => removePath(path)" role="button" class="fa fa-trash-alt text-danger hover-icon position-absolute"></i>
            </div>
        </div>

        <modal-component @hide="() => this.newFolder = false" :show="newFolder" :classList="['col-10 col-md-7 col-lg-5']" :title="t('new.newfolder')">
            <form @submit.prevent="makeDir" class="p-2">
                <label for="foldername">{{t('new.foldername')}}</label>
                <input required class="form-control" type="text" id="foldername" name="foldername" v-model="folderName" :placeholder="t('new.foldername')" />

                <button type="submit" class="btn btn-success my-2">
                    {{t('treinos.create')}}
                </button>
            </form>
        </modal-component>

        <modal-component @hide="() => this.show = false" :show="show" :classList="['col-11 col-lg-10']" :title="t('files.add')">
            <div class="row col-12 m-0 p-1">
                <button class="btn btn-outline-primary mx-1" @click="clickSelect">
                    <i class="fa fa-plus-circle"></i> {{t('new.addfiles')}}
                </button>
                <button class="btn btn-outline-danger mx-1" @click="cleanFiles">
                    <i class="fa fa-trash-alt"></i> {{t('new.cleanfiles')}}
                </button>
            </div>
            <form @submit.prevent="send">
                <div class="col-12 p-2">
                    <input type="file" @change="addFiles" multiple ref="fileinput" class="d-none">
                    <div class="row p-1 m-0 col-12">
                        <div v-for="(image, i) in images" :key="i" class="file-container p-2 col-6 col-md-4 col-lg-3 position-relative">
                            <img :src="getObjectUrl(image.file)" class="col-12 img" />
                            <input type="text" class="form-control" required v-model="image.name" placeholder="Nome do ficheiro"  />
                            <i @click="() => removeFile(i)" role="button" class="fa fa-trash-alt text-danger hover-icon position-absolute"></i>
                        </div>
                    </div>
        
                    <button v-if="images.length > 0" type="submit" class="btn btn-success my-2">
                        Enviar
                    </button>
                </div>
            </form>
        </modal-component>
    </div>
</template>

<script>
    import Api from '@/utilities/Api'
    import {mapActions, mapMutations} from 'vuex'
    import folderIcon from '../../assets/icons/folder.svg'
    import {t} from '../../lang/index.js'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'

    export default {
        name: 'FilesView',
        data() {
            return {
                path: '',
                files: [],
                folderIcon,
                selected: {},
                images: [],
                show: false,
                newFolder: false,
                folderName: 'Nova pasta'
            }
        },
        created() {
            document.title = "Gifs"
            this.goHome()
        },
        components: {
            ModalComponent
        },
        methods: {
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'post', 'delete']), t,
            getFullPath(path) {
                return `${Api.url}images/GIFS/${path}`
            },
            select(image) {
                if (this.selected[image]) {
                    delete this.selected[image]
                } else {
                    this.selected[image] = true
                }
            },
            go(dir) {
                this.path = dir
                this.selected = {}
                this.getPath(dir)
            },
            goHome() {
                this.go('')
            },
            goDir(index) {
                let fullPath = ''
                for (let i = 0; i <= index; i++) {
                    fullPath += this.stack[i] + '/'
                }
                
                console.log("Go to dir: ", this.stack[index], "Full: ", fullPath)
                this.go(fullPath)
            },

            async getPath(path = '') {
                this.SET_LOADING(true)
                this.files = []

                try {
                    const {data} = await this.get({url: 'admin/gifs?path=' + path})
                    this.files = data.files
                    console.log(data)
                } catch(e) {
                    alert("Ocorreu um erro buscando os arquivos")
                    console.log(e)
                } finally {
                    this.SET_LOADING(false)
                }
            },

            async remove() {
                const formData = new URLSearchParams()
                const images = Object.keys(this.selected)
                
                if (images.length < 1 || !confirm(this.t("general.do-you-want"))) {
                    return
                }
                for (let i = 0; i < images.length; i++) {
                    formData.append(`paths[${i}]`, images[i])
                }

                this.SET_LOADING(true)
                console.log(formData.toString())

                const res = await this.delete({url: 'admin/gifs?' + formData.toString()})

                if (res.status >= 200 && res.status < 300) {
                    this.go(this.path)
                    alert(this.t("general.deleted"))
                } else {
                    console.log(res)
                    alert("Erro")
                }
                this.SET_LOADING(false)
            },
            
            removeFile(index) {
                this.images.splice(index, 1)
            },

            addFiles(event) {
                console.log(event.target.files)
                if (event.target && event.target.files) {
                    const files = event.target.files
                    if (files.length > 0) {
                        for (const file of files) {
                            const extension = file.name.split('.').pop()
                            if (!['webp', 'gif'].includes(extension)) {
                                alert(t('new.onlygifs'))
                                continue
                            }
                            const baseName = file.name.substring(0, file.name.lastIndexOf('.'))
                            this.images.push({name: baseName, file: file})
                        }
                    }
                }
            },

            getObjectUrl(blob) {
                return URL.createObjectURL(blob)
            },

            async send() {
                if (this.images.length > 0) {
                    this.SET_LOADING(true)
                    const formData = new FormData()

                    formData.append("path", this.path)
                    for (const image of this.images) {
                        formData.append(`images[${image.name}]`, image.file)
                        console.log(image)
                    }

                    const res = await this.post({url: "admin/gifs", formData: true, data: formData})
                    
                    if (res.status >= 200 && res.status < 300) {
                        alert(t("files.added"))
                    } else {
                        alert("Error\n\n" + res.data?.message)
                    }

                    this.go(this.path)
                    this.images = []
                    console.log(res)
                    this.show = false
                    this.SET_LOADING(false)
                } else {
                    return
                }
            },

            clickSelect() { this.$refs.fileinput.click() },
            cleanFiles() { this.images = [] },

            async makeDir() {
                this.SET_LOADING(true)

                const res = await this.post({url: "admin/gifs/mkdir", formData: false, data: {
                    name: this.folderName,
                    path: this.path
                }})
                
                console.log(res)
                if (res.status >= 200 && res.status < 300) {
                    this.go(this.path)
                    alert(t("new.foldercreated"))
                } else {
                    alert("Error\n\n" + res.data?.message)
                }
                this.newFolder = false
                this.SET_LOADING(false)
            },

            async removePath(path) {
                if (confirm(t('general.do-you-want'))) {
                    this.SET_LOADING(true)
    
                    const res = await this.delete({url: `admin/gifs?paths[0]=${path}`})
    
                    if (res.status >= 200 && res.status < 300) {
                        this.go(this.path)
                        alert(this.t("general.deleted"))
                    } else {
                        console.log(res)
                        alert("Erro\n\n" + res.data?.message)
                    }
                    this.SET_LOADING(false)
                }
            }
        },

        computed: {
            stack() {
                console.log(this.path.split('/').filter(value => value.length > 0))
                return this.path.split('/').filter(value => value.length > 0)
            },
        }
    }
</script>

<style scoped lang="scss">
    .file-container {
        transition: all .3s ease-in-out;
        border-radius: 7px;

        .img {
            aspect-ratio: 1 / 1; /* Define a proporção 1:1 (quadrado) */
            object-fit: contain;
        }

        .hover-icon {
            cursor: pointer;
            top: 2rem;
            right: 2rem;
        }
    }
</style>
